import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ProcesoLayout } from '../layouts';
import { api } from '../services';

const AntesComenzarPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const currentLang = useSelector(({ config }) => config.language);
  const currentStep = useSelector(({ step }) => step.activeStep);
  const currentTask = useSelector(({ task }) => task.activeTask);
  const { fetchBeforeStartingData } = api();

  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      try {
        const response = await fetchBeforeStartingData(currentLang);
        if (!ignore) {
          setData(response);
        }
      } catch (e) {
        setError(e.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [currentLang, fetchBeforeStartingData]);

  return (
    <ProcesoLayout
      data={data}
      loading={loading}
      error={error}
      toLastStepTask={`/etapa/${currentStep}/tarea/${currentTask}`}
    />
  );
};

export default AntesComenzarPage;
